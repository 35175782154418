.header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centraliza verticalmente os itens no cabeçalho */
  padding: 1rem;
  margin: 0; 
  background-color: #354173;
  color: #fff;
  overflow-x: hidden; 
}

.header h1 {
  margin: 0;
  display: flex; /* Adiciona display flex para centralizar verticalmente */
  align-items: center; /* Centraliza verticalmente o ícone e o texto */
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 0.5rem; /* Reduzido de 1rem para 0.5rem */
  margin: 0; 
  padding: 0; 
}

.header nav ul li {
  margin: 0;
}

.header nav ul li a {
  color: #fff;
  text-decoration: none;
}

.header nav ul li a:hover {
  text-decoration: underline;
}

.logo {
  font-size: 22px;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-img {
  height: 1.5em;
  margin-right: 0.3em;
}

.logo a {
  text-decoration: none;
  color: #fff;
}

/* Dispositivos Móveis */
@media only screen and (max-width: 768px) {
  .logo {
    font-size: 20px;
  }

  .header {
    padding: 1rem;
  }

  .header nav ul {
    gap: 0.3rem; /* Ajuste o espaço entre os itens no mobile */
  }
}