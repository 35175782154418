.portfolio {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    margin: 10px;
  }
  
  .carousel-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .carousel-item h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  
  .carousel-item p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
