.about-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 190px;
  margin-bottom: 190px;
}

.member-info {
  text-align: center;
}

.member-info img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.member-bio {
  max-width: 600px;
  margin-left: 100px;
}

.member-bio h2 {
  margin-top: 20px;
}

.member-bio p {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .about-detail {
    flex-direction: column;
    margin-left: 0.1px;
    margin-right: 0.1px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .member-info,
  .member-bio {
    padding: 10px;
    text-align: center;
    margin-left: 0px;
  }

  .member-info img {
    width: 100px;
    height: 100px;
    margin-bottom: 5px;
  }
}