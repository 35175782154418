.contact-description {
  background-color: #e0e5f8;
  position: relative;
  width: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 80px;
  border-radius: 10px;

}

.contact-description-left {
  width: 30%;
  text-align: center;

}

.contact-description-right {
  width: 70%;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  max-width: 98%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .contact-description {
    flex-direction: column;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 83%;
  }

  .contact-description-left {
    width: 100%;
    text-align: center;
  }

  .contact-description-right {
    width: 100%;
    padding: 20px;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    max-width: 93%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}