.home-description {
  background-color: #e0e5f8;
  position: relative;
  width: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 80px; 
  border-radius: 10px;
}

.home-description p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .home-description {
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 83%;
  }

  .home-description p {
    font-size: 0.8rem;
  }

  .home-description-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-description-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-description-left img {
    width: 80%; 
    margin-bottom: 20px; 
  }
}