body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

main {
  flex: 1;
  padding-bottom: 4rem;
}


.footer {
  background-color: #354173;
  color: #fff;
  padding: 0.6rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.footer-content {
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer p {
  margin: 0;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  font-size: 0.9rem; 
}