.blog-post {
  display: flex;
  align-items: flex-start;
  margin: 10px;
}

.banner {
  width: 200px;
  margin-right: 20px;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-content {
  flex: 1;
}

@media (max-width: 768px) {
  .blog-post {
    flex-direction: column;
    margin: 1px;
  }

  .banner {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}