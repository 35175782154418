.privacy-detail {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-detail h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
    color: #333;
  }
  
  .privacy-detail h2 {
    font-size: 1.5em;
    margin-top: 1em;
    color: #444;
  }
  
  .privacy-detail p {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 1em;
  }
  
  .privacy-detail ul {
    list-style-type: disc;
    margin-left:
  }  