.blog-page {
  padding: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.blog-page h1 {
  font-size: 24px;
  margin-bottom: 20px;

}

/*Dispositivos Móveis */
@media only screen and (max-width: 768px) {
  .blog-page {
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}