.blog-detail {
  padding: 20px;
}

.blog-detail h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.blog-detail p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.blog-detail img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  max-height: 80vh;
}

@media (max-width: 767px) {
  .blog-detail img {
    display: block;
    margin: 0 auto 50px;
  }
}

@media (min-width: 768px) {
  .blog-detail {
    margin: 0 50px; 
  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: 20px;
}

.not-found-container img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.not-found-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.not-found-container p {
  font-size: 18px;
  color: #666;
  text-align: center;
}