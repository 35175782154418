.aboutpage {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.aboutpage h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.aboutpage p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.team-member {
  text-align: center;
  padding: 20px;
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-member h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.team-member p {
  font-size: 14px;
  text-decoration: none;
  color: #666;
}


.team-member a {
  text-decoration: none;
  color: #666;
}

/*Dispositivos Móveis */
@media only screen and (max-width: 768px) {
  .aboutpage {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}